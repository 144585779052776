var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500px" },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "v-flex",
        { staticClass: "popup-content", attrs: { "d-flex": "", "pa-4": "" } },
        [
          _c(
            "v-layout",
            { attrs: { column: "", "justify-center": "" } },
            [
              _c(
                "v-flex",
                {
                  staticClass: "popup-header",
                  attrs: { "d-flex": "", shrink: "", "justify-center": "" },
                },
                [_vm._v(" Hints for " + _vm._s(_vm.mission.title) + " ")]
              ),
              _vm.teamHintsTakenIndex == 0
                ? _c(
                    "v-flex",
                    {
                      staticClass: "text-xs-center popup-text",
                      attrs: { shrink: "", "mt-2": "" },
                    },
                    [_vm._v(" Use a hint if you are stuck"), _vm._t("default")],
                    2
                  )
                : _vm._e(),
              _c(
                "v-flex",
                { staticStyle: { "margin-top": "10px" } },
                _vm._l(_vm.teamHintsTaken, function (hint, i) {
                  return _c("div", { key: i, staticClass: "hint-revealed" }, [
                    _vm._v(
                      " " +
                        _vm._s(i + 1) +
                        ". " +
                        _vm._s(_vm.teamHintsTaken[i].hintTaken) +
                        " "
                    ),
                  ])
                }),
                0
              ),
              _vm.moreHintsToBeTaken
                ? _c(
                    "v-layout",
                    {
                      attrs: {
                        shrink: "",
                        "mt-4": "",
                        "d-flex": "",
                        column: "",
                        "align-center": "",
                      },
                    },
                    [
                      _c(
                        "RtbButton",
                        {
                          staticClass: "mb-2",
                          on: {
                            click: function ($event) {
                              return _vm.takeHint()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " Take Hint #" +
                              _vm._s(_vm.teamHintsTakenIndex + 1) +
                              " for " +
                              _vm._s(_vm.hintPoints) +
                              " pts "
                          ),
                        ]
                      ),
                      _c(
                        "RtbButton",
                        {
                          attrs: { color: "danger" },
                          on: {
                            click: function ($event) {
                              return _vm.onClose()
                            },
                          },
                        },
                        [_vm._v(" Close ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "close-symbol",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.onClose()
                },
              },
            },
            [
              _c("SvgIcon", {
                attrs: { name: "times-regular", size: "small" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }