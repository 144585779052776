<template>
  <v-dialog v-model="visible" max-width="500px">
    <v-flex d-flex pa-4 class="popup-content">
      <v-layout column justify-center>
        <v-flex d-flex shrink justify-center class="popup-header">
          Hints for {{ mission.title }}
        </v-flex>
        <v-flex
          shrink
          mt-2
          class="text-xs-center popup-text"
          v-if="teamHintsTakenIndex == 0"
        >
          Use a hint if you are stuck<slot />
        </v-flex>
        <v-flex style="margin-top: 10px">
          <div
            v-for="(hint, i) in teamHintsTaken"
            :key="i"
            class="hint-revealed"
          >
            {{ i + 1 }}. {{ teamHintsTaken[i].hintTaken }}
          </div>
        </v-flex>

        <v-layout
          shrink
          mt-4
          d-flex
          column
          align-center
          v-if="moreHintsToBeTaken"
        >
          <RtbButton class="mb-2" @click="takeHint()">
            Take Hint #{{ teamHintsTakenIndex + 1 }} for {{ hintPoints }} pts
          </RtbButton>

          <RtbButton @click="onClose()" color="danger"> Close </RtbButton>
        </v-layout>
      </v-layout>
      <div class="close-symbol" @click.stop="onClose()">
        <SvgIcon name="times-regular" size="small" />
      </div>
    </v-flex>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import * as moment from "moment"
import { RtbButton } from "@/components/ui"
export default {
  name: "HintPopup",
  components: {
    RtbButton
  },
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: Boolean
  },
  computed: {
    ...mapGetters(["missionHintsArray", "teamID", "game", "onlineUsersArray"]),
    ...mapGetters("auth", ["user", "isHost", "isAudit", "isPlayer"]),
    ...mapGetters({
      mission: "getCurrentMission",
      teams: "chats",
      missionID: "currentMission"
    }),
    visible: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("change", value)
      }
    },
    teamHintsTaken() {
      return this.missionHintsArray.filter(
        hint => (hint.teamID = this.computedTeamID)
      )
    },
    hintPoints() {
      return (
        parseInt(this.mission.points / (this.mission?.hints?.length + 5)) * -1
      )
    },
    teamHintsTakenIndex() {
      return this.missionHintsArray.filter(
        hint => (hint.teamID = this.computedTeamID)
      ).length
    },
    moreHintsToBeTaken() {
      return this.teamHintsTakenIndex < this.mission?.hints?.length
    },
    computedTeamID() {
      if (this.$store.getters["group/canViewerSelectGlobalTeam"]) {
        return this.$store.getters["group/globalTeamID"]
      } else {
        return this.teamID
      }
    },
    team() {
      return this.teams[this.computedTeamID]
    }
  },
  methods: {
    takeHint() {
      var i = this.teamHintsTakenIndex
      var sTime = new Date()
      var theTime = moment(sTime).unix()
      var realTime = moment(sTime).format("LLLL")
      var points = parseInt(
        this.mission.points / (this.mission.hints.length + 5)
      )

      var payload = {
        missionID: this.missionID,
        userID: this.user.id,
        username: this.user.firstname,
        firstName: this.user.firstname,
        lastName: this.user.lastname,
        points: points * -1,
        time: theTime,
        teamID: this.team.id,
        game: this.game.name,
        gameID: this.game.theKey,
        teamname: this.team.name,
        mission: this.mission.name,
        hint: true,
        hintTaken: this.mission.hints[i],
        hintTakenIndex: i
      }
      const totalScorePayload = {
        userID: this.user.id,
        totalScore: this.team.totalScore - points,
        teamMission: true,
        teamID: this.team.id,
        teamScore: this.team.totalScore - points
      }

      this.$store.dispatch("setTeamScore", totalScorePayload)
      const teamMates = this.onlineUsersArray.filter(
        user => user.teamID === this.team.id
      )
      for (var i in teamMates) {
        var obj = {}
        obj.score = points * -1
        obj.userID = teamMates[i].id
        obj.clientID = this.game.clientID
        console.log("HINT POINT REMOVAL", obj)
        this.$store.dispatch("setScore", obj)
      }

      this.$store.dispatch("addPlay", payload)
    },
    onClose() {
      this.visible = false
    }
  }
}
</script>

<style>
.hint-revealed {
  font-size: 18px;
  padding: 6px;
}
</style>
